<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  color: #dee6ff;
  &-wrapper {
    width: 77.2%;
    max-width: 1112px;
    margin: 0 auto;
    padding: 130px 0;
    &-nav {
      width: 100%;
      height: 60px;
      background: #0f1118;
      display: flex;
      justify-content: flex-start;
      &-item {
        width: 150px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        img {
          width: 20px;
          vertical-align: middle;
        }
      }
    }
    &-selfInfo {
      width: 100%;
      height: 136px;
      margin-top: 10px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
        // &-main {
        //   height: 40px;
        //   margin-left: 5%;
        //   border-left: 2px solid #ccc;
        //   padding-left: 1%;
        //   font-size: 20px;
        //   line-height: 40px;
        // }
      }
      &-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        line-height: 40px;
        margin-top: 20px;
        margin-left: 5%;
        font-size: 14px;
        font-weight: 500;
        color: rgba(222, 230, 255, 1);
        p {
          margin: 0;
        }
        &-left {
          width: 15%;
          p::before {
            content: " · ";
          }
        }
      }
    }
    &-button {
      width: 140px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin: 0 auto;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      border-radius: 18px;
      cursor: pointer;
    }
  }
  .actived {
    font-size: 14px;
    font-weight: 600;
    color: rgba(85, 119, 224, 1);
    background: rgba(30, 33, 43, 1);
    border-bottom: 2px solid rgba(85, 119, 224, 1);
  }
  .missing-information {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 9, 17, 0.8);
    z-index: 12;
    &-container {
      width: 460px;
      height: 240px;
      background: rgba(23, 25, 32, 1);
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-close {
        width: 18px;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        margin: 0;
        text-align: center;
        &:nth-child(2) {
          margin: 30px 0;
        }
        &:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          padding: 0 23px;
        }
      }
    }
    &-paaaword {
      width: 400px;
      height: 367px;
      background: rgba(15, 17, 24, 1);
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-family: "PingFangSC-Regular", "PingFang SC";
      font-weight: 400;
      color: rgba(85, 119, 224, 1);
      line-height: 25px;
    }
  }
  .sure-button {
    width: 140px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    line-height: 40px;
    letter-spacing: 0;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }
  /deep/ .el-input__inner {
    height: 30px;
    border: 1px solid #999;
    border-radius: 0px;
    background: #070911;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    background: rgba(15, 17, 24, 1);
    border: 1px solid rgba(222, 230, 255, 0.3);
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-nav">
        <div class="person-center-wrapper-nav-item"
             v-for="(item, index) in personCenterNav"
             :key="index"
             :class="index === activedIndex ? 'actived' : ''">
          <img :src="item.img"
               alt="">
          {{ $t(item.name) }}
        </div>
      </div>
      <IDC-award @toSignUp="toSignUp"></IDC-award>
    </div>
    <Common-footer :style="{background: '#0F1118'}"></Common-footer>
  </div>
</template>

<script>
import CommonFooter from '../components/common-footer';
import IDCAward from './IDC-award.vue';

export default {
  name: 'person-center',
  data () {
    return {
      missingInformation: false,
      changePasswordModal: false,
      infoModal: false,
      oldPassword: '',
      newPassword: '',
      newPassword1: '',
      personCenterNav: [
        {
          name: 'moreT',
          img: require('../assets/idc-award/6.png')
        }
      ],
      activedIndex: 0,
      userInfo: {},
      org_job: [],
      productionType: []
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    toSignUp () {
      if (!this.$Cookies.get('cecc-user')) {
        this.$router.push({
          name: 'login'
        })
      } else {
        this.$router.push({
          name: 'personCenter',
          params: {
            from: 'IDC'
          }
        })
      }
    },
  },
  components: {
    CommonFooter,
    IDCAward
  }
}
</script>
